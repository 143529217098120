import { graphql } from "gatsby";
import React from "react";
import CookieBanner from "../components/CookieBanner";
import Footer from "../components/Footer";
import Nav from "../components/Nav";
import Calculator from "../components/Calculator";
import { CheckIcon } from "@heroicons/react/outline";
import OutSourceButton from "../components/OutSourceButton";
import BlockContent from "../components/BlockContent";

const Refinance = ({
  data: {
    sanityRefinance: {
      mainHeading,
      heading1,
      text1,
      heading2,
      text2,
      introduction,
      refinaceCheck1,
      refinaceCheck2,
      refinaceCheck3,
    },
  },
}) => {
  return (
    <>
      <Nav />

      <div className="px-8">
        <div className="text-heading-4 md:text-heading-2 text-gray-dark py-8">
          {mainHeading}
        </div>
        <div className="flex md:flex-row flex-col md:space-x-4 py-8 place-content-evenly  text-center">
          <div className="grid place-content-center"></div>
          <div className="md:w-1/3 bg-white shadow-md p-4 mb-4">
            <div className="grid place-content-center">
              <svg
                width="33"
                height="32"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M23.733 22.667c-.555 0-.972-.228-1.25-.684-.277-.455-.294-.927-.05-1.416l1.6-3.234h-4.2a1.929 1.929 0 0 1-1.416-.583 1.929 1.929 0 0 1-.584-1.417V10c0-.556.195-1.028.584-1.417A1.929 1.929 0 0 1 19.833 8h5.334c.555 0 1.027.194 1.416.583.39.39.584.861.584 1.417v7.133c0 .156-.017.311-.05.467a1.81 1.81 0 0 1-.15.433l-1.934 3.834a1.312 1.312 0 0 1-.516.583 1.458 1.458 0 0 1-.784.217Zm-12 0c-.555 0-.972-.228-1.25-.684-.277-.455-.294-.927-.05-1.416l1.6-3.234h-4.2a1.929 1.929 0 0 1-1.416-.583 1.929 1.929 0 0 1-.584-1.417V10c0-.556.195-1.028.584-1.417A1.929 1.929 0 0 1 7.833 8h5.334c.555 0 1.027.194 1.416.583.39.39.584.861.584 1.417v7.133c0 .156-.017.311-.05.467a1.81 1.81 0 0 1-.15.433l-1.934 3.834a1.312 1.312 0 0 1-.516.583 1.458 1.458 0 0 1-.784.217Z"
                  fill="#0FF"
                />
              </svg>
            </div>
            <div className="text-heading-4 pb-4">{heading1}</div>
            <div>
              <BlockContent blocks={text1} />
            </div>
          </div>
          <div className="md:w-1/3 bg-white shadow-md p-4 mb-4">
            <div className="grid place-content-center">
              <svg
                width="33"
                height="32"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M23.733 22.667c-.555 0-.972-.228-1.25-.684-.277-.455-.294-.927-.05-1.416l1.6-3.234h-4.2a1.929 1.929 0 0 1-1.416-.583 1.929 1.929 0 0 1-.584-1.417V10c0-.556.195-1.028.584-1.417A1.929 1.929 0 0 1 19.833 8h5.334c.555 0 1.027.194 1.416.583.39.39.584.861.584 1.417v7.133c0 .156-.017.311-.05.467a1.81 1.81 0 0 1-.15.433l-1.934 3.834a1.312 1.312 0 0 1-.516.583 1.458 1.458 0 0 1-.784.217Zm-12 0c-.555 0-.972-.228-1.25-.684-.277-.455-.294-.927-.05-1.416l1.6-3.234h-4.2a1.929 1.929 0 0 1-1.416-.583 1.929 1.929 0 0 1-.584-1.417V10c0-.556.195-1.028.584-1.417A1.929 1.929 0 0 1 7.833 8h5.334c.555 0 1.027.194 1.416.583.39.39.584.861.584 1.417v7.133c0 .156-.017.311-.05.467a1.81 1.81 0 0 1-.15.433l-1.934 3.834a1.312 1.312 0 0 1-.516.583 1.458 1.458 0 0 1-.784.217Z"
                  fill="#0FF"
                />
              </svg>
            </div>
            <div className="text-heading-4 pb-4">{heading2}</div>
            <div>
              <BlockContent blocks={text2} />
            </div>
          </div>
        </div>

        <div className="text-lg py-8">
          <BlockContent blocks={introduction} />
        </div>
        <Calculator
          exampleInterest={0.0599}
          startPrice={990}
          htmlInterest={"5,99"}
        />

        <div className="py-8 place-content-center items-center flex flex-col">
          <div className="md:w-1/2 w-full place-content-center bg-white shadow-md p-4 content-center">
            <div className="flex flex-row">
              <CheckIcon className="w-8 h-8 mr-4" />
              {refinaceCheck1}
            </div>

            <div className="flex flex-row">
              <CheckIcon className="w-8 h-8 mr-4" />
              {refinaceCheck2}
            </div>

            <div className="flex flex-row">
              <CheckIcon className="w-8 h-8 mr-4" />
              {refinaceCheck3}
            </div>
          </div>
        </div>

        <CookieBanner />
      </div>

      <div className="flex place-content-center">
        <OutSourceButton />
      </div>
      <Footer />
    </>
  );
};

export const query = graphql`
  query {
    sanityRefinance(_id: { regex: "/refinance$/" }) {
      mainHeading
      heading1
      text1: _rawText1
      heading2
      text2: _rawText2
      introduction: _rawIntroduction
      refinaceCheck1
      refinaceCheck2
      refinaceCheck3
    }
  }
`;

export default Refinance;
